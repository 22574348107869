#container-members{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../images/vlad-hilitanu-UIigFW4P7L8-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 2rem 16vw;

  h1{
    display: block;
    text-align: center;
    font-family: var(--bold-font), Helvetica, sans-serif;
    font-size: 2.5rem;
    color: var(--main-color-orange);
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px solid var(--main-color-orange);
    max-width: 450px;
    border-radius: 30px;
    padding: 0.2rem 1rem;
    margin-top: 12rem;
    margin-bottom: 5rem;
  }

  .mem-back{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    border: 2px solid var(--main-color-orange);
    width: 90%;
    align-items: center;
    justify-content: center;
    justify-items: start; /* ajouter cette propriété */
    background-color: rgba(0, 0, 0, .3);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    margin-bottom: 5rem;
    padding: 3rem;
    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
      justify-items: center;
    }

    .mem-list{
      display: grid;
      grid-template-columns: auto 1fr;
      grid-template-rows: repeat(2, 1fr);
      gap: 10px;
      max-width: 300px;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;

      img{
        grid-row: 1 / 3; /* occupe les deux premières lignes */
        width: 80px;
        margin-right: 10px;
        align-content: center;
      }

      .status{
        grid-row: 1 / 2; /* occupe la première ligne */
        text-align: center;
        color: var(--main-color-white);
        //font-size: 1rem;
        font-family: var(--bold-font);
      }

      .identity{
        grid-row: 2 / 3; /* occupe la deuxième ligne */
        text-align: center;

        span {
          text-transform: uppercase;
        }
      }
    }
  }
}