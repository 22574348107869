#container-form{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../images/james-wiseman-IebZAH6kaNw-unsplash.jpg");
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.main-form {
  border: 1px solid var(--main-color-orange);
  min-height: 800px;
  position: relative;
  flex-grow: 1;
  /* padding-bottom:100px; */
}

.login-section{
  position: relative;
  border: 2px solid var(--main-color-orange);
  height: 490px;
  width: 400px;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 40%);
  background-color: rgba(0, 0, 0, .3);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  transition: .3s;
  overflow: hidden;
}

.login-section:hover{
  box-shadow: 0 0 60px var(--main-color-orange);
}

.login-section .formbox{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.formbox h2 {
  text-align: center;
  font-size: 2em;
}

.formbox .input-box{
  position: relative;
  width: 340px;
  height: 50px;
  border-bottom: 2px solid var(--main-color-white);
  margin: 30px 0;
}

.input-box input{
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.2em;
  padding-right: 28px;
  color: var(--main-color-white);
}

.input-box label{
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.2em;
  color: var(--main-color-white);
  transition: .3s ease;
}

.input-box .icon{
  position: absolute;
  top: 13px;
  right: 0;
  font-size: 1.5em;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label{
  top: -5px;
  color: var(--main-color-orange);
  font-size: 1em;
}

.rem-password{
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  margin: -15px 0 15px;
}

.rem-password label input{
  accent-color: var(--main-color-orange);
  margin-right: 5px;
}

.rem-password a{
  color: var(--main-color-white);
}

.rem-password a:hover{
  color: var(--main-color-orange);
}

.btn-val{
  width: 100%;
  height: 45px;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.3em;
  color: var(--main-color-white);
  background: var(--main-color-orange);
  box-shadow: rgba(0, 0, 0, 0.4);
}

.crea-account{
  font-size: 0.8em;
  text-align: center;
  margin: 25px;
}

.crea-account p a{
  color: var(--main-color-white);
  font-family: var(--bold-font), Helvetica, sans-serif;
  text-decoration: none;
}

.crea-account p a:hover{
  color: var(--main-color-orange);
}

.login-section .formbox.register{
  transform: translateY(-460px);
  transition: transform .7s ease;
}

.login-section.active .formbox.register{
  transform: translateY(0);
  transition-delay: .5s;
}

.login-section .formbox.login{
  transform: translateY(0px);
  transition: transform .7s ease;
}

.login-section.active .formbox.login{
  transform: translateY(430px);
  transition-delay: 0s;
}