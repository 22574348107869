:root {
  --reg-font: 'fontRegular';
  --lig-font: 'fontLight';
  --bold-font: 'fontBold';
  --italic-font: 'fontItalic';
  --italic-bold-font: 'fontBoldItalic';

  --main-color-orange: #133A1B;
  --main-color-green: #38163B;
  //--main-color-blue: #187FA1;
  --main-color-white: #f1f1f1;
  --main-color-black: #111;

  --main-color-lightgrey: #bbbbbb;
  --main-color-darkgrey: #666666;
}

//--main-color-orange: #A14228;
//--main-color-vert: #1b6e55;
//--main-color-blue: #187FA1;