//section{
//  display: flex;
//  flex-direction: column;
//  align-items: center;
//  padding: 5rem 0 5rem;
//}

.sectionBlogs{
  //display: flex;
  //flex-direction: column;
  width: 100%;
  background-image: url('../../images/reagan-freeman-4Eu5Qsz7jmI-unsplash.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 0 8vw;

  .blogs-intro{
    /* max-width: 1100px; */
    margin: 0 auto;
    padding: 0 10px;

    h1{
      display: block;
      text-align: center;
      font-family: var(--bold-font), Helvetica, sans-serif;
      font-size: 2.5rem;
      color: var(--main-color-orange);
      background-color: rgba(255, 255, 255, 0.5);
      border: 2px solid var(--main-color-orange);
      max-width: 450px;
      border-radius: 30px;
      padding: 0.2rem 1rem;
      margin-top: 12rem;
      margin-bottom: 5rem;
    }
  }

  .news-grid{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    align-items: center;
    justify-content: center;
    //background-color: var(--main-color-orange);

    .sec-blogsList{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      //width: 400px;

      .img-container{
        width: 400px;
        height: 250px;
        border-radius: 30px;
        background-color: var(--main-color-white);
        margin: 0 1em;
        overflow: hidden;

        .blogs-img{
          //display: flex;
          //flex-direction: row;
          //justify-content: space-around;
          width: 100%;
          //margin-bottom: 2em;
        }
      }

      .blog-resume{
        position: relative;
        top: -100px;
        background-color: rgba(0, 0, 0, .7);
        text-align: center;
        border-radius: 30px;
        max-width: 300px;

        #blogs-title{
          display: block;
          color: var(--main-color-white);
          background-color: transparent;
          font-size: 1.5rem;
          padding-top: 1rem;
          max-width: 300px;
          margin-bottom: 4rem;
        }

        .blogs-txt{
          max-width: 300px;
          line-height: 1.2rem;
          font-size: 0.7rem;
          padding: 0 2rem 1rem;
          color: var(--main-color-white);
        }
      }
    }
  }
}