@charset "UTF-8";
html {
  --res: calc(0.01 * 15vmin);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.box-shadow {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.8);
}

strong {
  font-family: var(--bold-font);
}

em {
  font-family: var(--italic-font);
}

strong em {
  font-family: var(--italic-bold-font);
}

:root {
  --reg-font: "fontRegular";
  --lig-font: "fontLight";
  --bold-font: "fontBold";
  --italic-font: "fontItalic";
  --italic-bold-font: "fontBoldItalic";
  --main-color-orange: #133A1B;
  --main-color-green: #38163B;
  --main-color-white: #f1f1f1;
  --main-color-black: #111;
  --main-color-lightgrey: #bbbbbb;
  --main-color-darkgrey: #666666;
}

@font-face {
  font-family: fontRegular;
  src: url(../fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: fontBold;
  src: url(../fonts/Poppins-Bold.ttf);
}
@font-face {
  font-family: fontLight;
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: fontItalic;
  src: url(../fonts/Poppins-Italic.ttf);
}
@font-face {
  font-family: fontBoldItalic;
  src: url(../fonts/Poppins-BoldItalic.ttf);
}
*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--reg-font), Helvetica, sans-serif;
  color: var(--main-color-white);
  background-color: var(--main-color-black);
  overflow-x: hidden;
}
body section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0 10rem;
}
body section h2 {
  display: block;
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 2rem;
  color: var(--main-color-black);
  background-color: var(--main-color-white);
  max-width: 300px;
  border-radius: 30px;
  padding: 0.2rem 1rem;
  margin-bottom: 5rem;
}
body section p {
  max-width: 800px;
  line-height: 2rem;
  font-size: 1.2rem;
  padding-top: 1rem;
}
body section ul li {
  max-width: 800px;
  line-height: 2rem;
  padding-top: 1rem;
}
body section h2 {
  display: block;
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 1.5em;
  color: var(--main-color-black);
  background-color: var(--main-color-white);
  max-width: 300px;
  border-radius: 30px;
  padding: 0.2em 1em;
  /* margin-top: 5em; */
  margin-bottom: 2em;
}
body .sectionOne {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: 1;
  width: 100%;
}
body .sectionOne .intro {
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 30px;
  color: var(--main-color-black);
  font-size: 1.7rem;
  margin-top: 5rem;
  padding: 5rem;
  line-height: 3rem;
}
body .sectionOne .intro span {
  font-family: var(--bold-font);
  color: var(--main-color-orange);
}
body .sectionOne .sec-mission {
  background-color: var(--main-color-orange);
  padding: 10rem 0 10rem;
}
body .sectionOne .sec-mission h2 {
  text-transform: uppercase;
}
body .sectionOne .sec-mission .mission-txt {
  max-width: 1000px;
  line-height: 1.5rem;
  font-size: 1.2rem;
  padding-top: 1rem;
}
body .sectionOne .sec-mission:nth-child(4n+2) {
  background: var(--main-color-orange);
}
body .sectionOne .sec-mission:nth-child(4n+2) h2 {
  color: var(--main-color-orange);
}
body .sectionOne .sec-mission:nth-child(2n+3) {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}
body .sectionOne .sec-mission:nth-child(4n+4) {
  background: var(--main-color-green);
}
body .sectionOne .sec-mission:nth-child(4n+4) h2 {
  color: var(--main-color-green);
}
@media screen and (max-width: 925px) {
  body .sectionOne .intro {
    margin-top: 2rem;
    font-size: 1.5rem;
    padding: 5rem 2rem 5rem;
  }
  body .sectionOne .sec-mission {
    padding: 5rem 2rem 5rem;
  }
  body .sectionOne .sec-mission .mission-txt {
    width: 100%;
  }
}

nav {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
  padding: 0 100px;
  box-sizing: border-box;
}
nav .Logo_loba {
  max-width: 180px;
  margin-top: 110px;
}
nav .navlinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav .navlinks ul {
  display: flex;
  align-items: center;
}
nav .navlinks ul li {
  display: flex;
  list-style-type: none;
  padding: 0 20px;
  align-items: center;
}
nav .navlinks ul li a {
  overflow: hidden;
  position: relative;
  display: inline-block;
  color: var(--main-color-black);
}
nav .navlinks ul li a::before,
nav .navlinks ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}
nav .navlinks ul li a::before {
  background-color: var(--main-color-orange);
  height: 2px;
  bottom: 0;
  transform-origin: 100% 50%;
  transform: scaleX(0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
nav .navlinks ul li a::after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  transform-origin: 100% 50%;
  transform: translate3d(200%, 0, 0);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: var(--main-color-orange);
}
nav .navlinks ul li a:hover::before {
  transform-origin: 0 50%;
  transform: scaleX(1);
}
nav .navlinks ul li a:hover::after {
  transform: translate3d(0, 0, 0);
}
nav .navlinks ul li a span {
  display: inline-block;
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}
nav .navlinks ul li a:hover span {
  transform: translate3d(-200%, 0, 0);
}
nav .navlinks ul li .donate {
  color: var(--main-color-white);
  background-color: var(--main-color-orange);
  padding: 6px 10px;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}
nav .navlinks ul li .donate:hover {
  color: var(--main-color-orange);
  background-color: var(--main-color-white);
  box-shadow: 0 0 0 2px var(--main-color-orange) inset;
  transition: 0.3s ease-out;
}
nav .navlinks form {
  position: relative;
  display: inline-block;
  border: 1.5px solid var(--main-color-orange);
  border-radius: 5px;
}
nav .navlinks form:after {
  content: "▼";
  position: absolute;
  width: 30px;
  color: var(--main-color-white);
  font-weight: bold;
  font-size: 16px;
  right: 1px;
  bottom: 8px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  pointer-events: none;
  z-index: 2;
}
nav .navlinks form:before {
  content: "";
  right: 8px;
  top: 2px;
  width: 30px;
  height: 34px;
  background: var(--main-color-orange);
  position: absolute;
  pointer-events: none;
  display: block;
  z-index: 1;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
nav .navlinks form select {
  position: relative;
  width: 80px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: var(--main-color-white);
  color: var(--main-color-black);
  border: none;
  outline: none;
  font-size: 14px;
  padding: 10px 9px;
  margin: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  cursor: pointer;
  height: 38px;
}

/* Animation burger */
#navbar .containner {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

path {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}

path:nth-child(1) {
  transform-origin: 36% 40%;
}

path:nth-child(2) {
  stroke-dasharray: 29 299;
}

path:nth-child(3) {
  transform-origin: 35% 63%;
}

path:nth-child(4) {
  stroke-dasharray: 29 299;
}

path:nth-child(5) {
  transform-origin: 61% 52%;
}

path:nth-child(6) {
  transform-origin: 62% 52%;
}

.active path:nth-child(1) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}

.active path:nth-child(2) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}

.active path:nth-child(3) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}

.active path:nth-child(4) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}

.active path:nth-child(5) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}

.active path:nth-child(6) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}

/* Fin animation burger */
@media screen and (min-width: 1150px) and (max-width: 1400px) {
  nav {
    padding: 0 30px;
  }
  nav .navlinks ul li a {
    font-size: calc(10 * var(--res));
  }
}
@media screen and (min-width: 926px) and (max-width: 1149px) {
  nav {
    padding: 0 5px;
  }
  nav .navlinks ul li a {
    font-size: calc(8 * var(--res));
  }
}
@media screen and (min-width: 320px) and (max-width: 925px) {
  #navbar {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #navbar .Logo_loba {
    max-width: 140px;
    margin-top: 5px;
  }
  #navbar .containner {
    display: block;
    padding-right: 3rem;
  }
  #navbar .navlinks {
    top: 0;
    left: 0;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(7px);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
    transition: all 0.5s ease;
  }
  #navbar .navlinks ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  #navbar .navlinks ul li {
    margin: 20px 0;
  }
  #navbar .navlinks ul li a {
    margin-left: 0;
    color: var(--main-color-white);
    font-size: calc(35 * var(--res));
  }
  #navbar .navlinks ul li .donate:hover {
    color: var(--main-color-orange);
  }
  #navbar .navlinks form {
    margin-top: 2rem;
  }
  #navbar .navlinks.mobile-menu {
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
footer {
  padding: 2rem 16vw;
}
footer .newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
footer .newsletter h5 {
  display: flex;
  font-family: var(--bold-font);
  font-size: 1rem;
  color: var(--main-color-darkgrey);
  margin-right: 1rem;
  text-transform: uppercase;
}
footer .newsletter form {
  margin: 20px 0;
}
footer .newsletter form input {
  width: 20vw;
  height: 40px;
  padding: 0 15px;
  outline: none;
  border-radius: 10px;
  border: none;
}
footer .newsletter form button {
  width: 5rem;
  height: 40px;
  color: var(--main-color-white);
  background-color: var(--main-color-darkgrey);
  border: none;
  margin-right: 2rem;
  border-radius: 10px;
  transition: 0.3s ease-in-out;
}
footer .newsletter form button:hover {
  color: var(--main-color-black);
  background-color: var(--main-color-white);
}
footer hr {
  background-color: var(--main-color-lightgrey);
  border: 1px solid var(--main-color-lightgrey);
  margin: 30px 0;
}
footer .foo-about {
  display: block;
  text-align: center;
}
footer .foo-about h5 {
  display: flex;
  flex-direction: column;
  font-family: var(--bold-font);
  font-size: 1rem;
  color: var(--main-color-darkgrey);
  text-transform: uppercase;
}
footer .foo-about p {
  font-size: 0.8rem;
  padding-top: 0.5rem;
}
footer .foo-about .social-icons {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}
footer .foo-about .social-icons li {
  display: flex;
  list-style: none;
  justify-content: center;
}
footer .foo-about .social-icons li a {
  text-decoration: none;
  color: var(--main-color-darkgrey);
  font-size: 1.2rem;
  transition: 0.3s ease-in-out;
  padding-right: 2rem;
}
footer .foo-about .social-icons li a i {
  font-size: 2rem;
  padding: 0.5rem;
}
footer .foo-about .social-icons li a:hover {
  color: var(--main-color-white);
}
footer .foo-contact {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30px;
}
footer .foo-contact h5 {
  display: block;
  width: 100%;
  font-family: var(--bold-font);
  font-size: 1rem;
  text-align: center;
  color: var(--main-color-darkgrey);
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}
footer .foo-contact .foo-rens {
  margin: 10px 0;
}
footer .foo-contact .foo-rens h6 i {
  display: block;
  font-size: 1.5rem;
  text-align: center;
  color: var(--main-color-darkgrey);
}
footer .foo-contact .foo-rens ul {
  margin: 10px 0;
}
footer .foo-contact .foo-rens ul li {
  list-style: none;
  margin: 4px 15px;
  font-size: 0.8rem;
  text-align: center;
}
footer .foo-contact .foo-rens ul li i {
  text-decoration: none;
  font-size: 1rem;
  color: var(--main-color-lightgrey);
  margin-right: 0.5rem;
}
footer .foo-contact .foo-rens ul li a {
  color: var(--main-color-white);
  transition: 0.3s ease-in-out;
}
footer .foo-contact .foo-rens ul li a:hover {
  color: var(--main-color-darkgrey);
}
footer .foo-contact .foo-rens ul li span {
  font-family: var(--bold-font);
}

.wb-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(../../images/domenico-loia-hGV2TfOh0ns-unsplash.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.wb-page .wb-container {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  gap: 20px;
  width: 100%;
  align-content: center;
  justify-content: center;
  padding: 15rem 0 15rem;
}
.wb-page .wb-container a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.wb-page .wb-container a .wb-card {
  width: 350px;
  height: 500px;
  perspective: 1000px;
}
.wb-page .wb-container a .wb-card .wb-content-card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 25px;
  transition: all 0.15s ease-out;
}
.wb-page .wb-container a .wb-card .wb-content-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.wb-page .wb-container a .wb-glow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  opacity: 0.8;
  mix-blend-mode: hard-light;
  background: radial-gradient(circle at 50% 0%, rgb(184, 196, 211), transparent);
  transition: all 0.15s ease-out;
}
@media screen and (max-width: 1200px) {
  .wb-page .wb-container {
    grid-template-columns: 1fr;
  }
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0 12rem;
}

.sectionProjOne {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  z-index: 1;
  width: 100%;
}
.sectionProjOne .proj-intro {
  /* max-width: 1100px; */
  margin: 0 auto;
  padding: 0 10px;
}
.sectionProjOne .proj-intro h1 {
  display: block;
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 2.5rem;
  color: var(--main-color-orange);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--main-color-orange);
  max-width: 400px;
  border-radius: 30px;
  padding: 0.2rem 1rem;
  margin-top: 12rem;
  margin-bottom: 5rem;
}
.sectionProjOne .proj-intro .sec-pictos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
.sectionProjOne .proj-intro .sec-pictos .pictos-links {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
}
.sectionProjOne .proj-intro .sec-pictos a img {
  width: 200px;
  height: 200px;
  margin: 1rem;
}
.sectionProjOne .sec-sante {
  background-color: var(--main-color-orange);
  padding: 10em 0 10em;
}
.sectionProjOne .sec-sante h2 {
  text-transform: uppercase;
}
.sectionProjOne .sec-sante:nth-child(4n+2) {
  background: var(--main-color-orange);
}
.sectionProjOne .sec-sante:nth-child(2n+3) {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
}
.sectionProjOne .sec-sante:nth-child(2n+3) a h2 {
  color: var(--main-color-black);
}
.sectionProjOne .sec-sante:nth-child(4n+4) {
  background: var(--main-color-green);
}
.sectionProjOne .sec-sante:nth-child(4n+4) a h2 {
  color: var(--main-color-green);
}
.sectionProjOne .sec-sante h2 {
  color: var(--main-color-orange);
  margin-top: 2rem;
}
.sectionProjOne .sec-sante h3 {
  font-family: var(--bold-font), Helvetica, sans-serif;
  color: var(--main-color-white);
  font-size: 1.5rem;
  padding-top: 2rem;
}
.sectionProjOne .sec-sante .content {
  max-width: 1000px;
  line-height: 1.5rem;
  font-size: 1.2rem;
  padding-top: 1rem;
}
.sectionProjOne .sec-sante .content li {
  padding-top: 1rem;
}
@media screen and (max-width: 925px) {
  .sectionProjOne h1 {
    margin-bottom: 2rem;
  }
  .sectionProjOne .sec-pictos {
    padding: 0 0 5rem;
  }
  .sectionProjOne .sec-pictos .pictos-links {
    margin-top: 1rem;
  }
  .sectionProjOne .sec-sante {
    padding: 5rem 2rem 10rem;
  }
}

.sectionBlogs {
  width: 100%;
  background-image: url("../../images/reagan-freeman-4Eu5Qsz7jmI-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 0 8vw;
}
.sectionBlogs .blogs-intro {
  /* max-width: 1100px; */
  margin: 0 auto;
  padding: 0 10px;
}
.sectionBlogs .blogs-intro h1 {
  display: block;
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 2.5rem;
  color: var(--main-color-orange);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--main-color-orange);
  max-width: 450px;
  border-radius: 30px;
  padding: 0.2rem 1rem;
  margin-top: 12rem;
  margin-bottom: 5rem;
}
.sectionBlogs .news-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  align-items: center;
  justify-content: center;
}
.sectionBlogs .news-grid .sec-blogsList {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.sectionBlogs .news-grid .sec-blogsList .img-container {
  width: 400px;
  height: 250px;
  border-radius: 30px;
  background-color: var(--main-color-white);
  margin: 0 1em;
  overflow: hidden;
}
.sectionBlogs .news-grid .sec-blogsList .img-container .blogs-img {
  width: 100%;
}
.sectionBlogs .news-grid .sec-blogsList .blog-resume {
  position: relative;
  top: -100px;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  border-radius: 30px;
  max-width: 300px;
}
.sectionBlogs .news-grid .sec-blogsList .blog-resume #blogs-title {
  display: block;
  color: var(--main-color-white);
  background-color: transparent;
  font-size: 1.5rem;
  padding-top: 1rem;
  max-width: 300px;
  margin-bottom: 4rem;
}
.sectionBlogs .news-grid .sec-blogsList .blog-resume .blogs-txt {
  max-width: 300px;
  line-height: 1.2rem;
  font-size: 0.7rem;
  padding: 0 2rem 1rem;
  color: var(--main-color-white);
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10em 0 12em;
}

.sectionBlog {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: 1;
  width: 100%;
  background-color: var(--main-color-black);
}
.sectionBlog .blog-intro {
  /* max-width: 1100px; */
  margin: 0;
  padding: 0;
}
.sectionBlog .blog-intro .img-cont {
  width: 100%;
  max-height: 1000px;
  margin: 0 1em;
  overflow: hidden;
}
.sectionBlog .blog-intro .img-cont .blog-img {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 2em;
}
.sectionBlog .sec-blogList {
  background-color: var(--main-color-white);
  padding: 5em 0 5em;
}
.sectionBlog .sec-blogList .blog-res {
  position: relative;
  align-content: center;
  max-width: 1000px;
}
.sectionBlog .sec-blogList .blog-res #blog-title {
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 2.5em;
  color: var(--main-color-orange);
  max-width: 1000px;
  margin-bottom: 2em;
}
.sectionBlog .sec-blogList .blog-res .blog-ac {
  line-height: 1.5em;
  font-size: 1.5em;
  color: var(--main-color-black);
  text-align: center;
}
.sectionBlog .sec-blogList .blog-res .blog-ac::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -100px;
  background: var(--main-color-black);
  height: 5px;
  box-sizing: border-box;
  width: 100%;
}
.sectionBlog .sec-blogList .blog-dev {
  max-width: 1000px;
}
.sectionBlog .sec-blogList .blog-dev #blog-miniTitle {
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 1.5em;
  color: var(--main-color-orange);
  margin-top: 6em;
}
.sectionBlog .sec-blogList .blog-dev .blog-txt {
  line-height: 1.5em;
  font-size: 1em;
  padding: 0 2em 2em;
  color: var(--main-color-black);
}

#container-part {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../images/kigali-5256022_1920.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 2rem 16vw;
}
#container-part h1 {
  display: block;
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 2.5rem;
  color: var(--main-color-orange);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--main-color-orange);
  max-width: 450px;
  border-radius: 30px;
  padding: 0.2rem 1rem;
  margin-top: 12rem;
  margin-bottom: 5rem;
}
#container-part .part-back {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  border: 2px solid var(--main-color-orange);
  width: 90%;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-bottom: 5rem;
}
#container-part .part-back .part-list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
}
#container-part .part-back .part-list img {
  width: 80px;
  margin-right: 10px;
}
#container-part .part-back .part-list a {
  color: var(--main-color-white);
  font-size: 1em;
}

.contact-section {
  width: 100%;
  background-image: url(../../images/triston-dunn-rfj_wOYQkus-unsplash.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 15rem 0 15rem;
}
.contact-section .contact-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 1100px;
  width: 100%;
  margin: auto;
  background-color: var(--main-color-orange);
  box-shadow: 0 0 1rem hsla(0, 0%, 0%, 0.16);
  border-radius: 0.5rem;
  overflow: hidden;
}
.contact-section .contact-container .form-container {
  padding: 20px;
}
.contact-section .contact-container .form-container h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.contact-section .contact-container .form-container .contact-form {
  display: grid;
  row-gap: 1rem;
}
.contact-section .contact-container .form-container .contact-form input {
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--main-color-white);
  color: var(--main-color-black);
  padding: 10px;
  font-family: var(--reg-font);
  font-size: 0.9rem;
  border-radius: 0.4rem;
}
.contact-section .contact-container .form-container .contact-form textarea {
  font-family: var(--reg-font);
  width: 100%;
  border: none;
  outline: none;
  background-color: var(--main-color-white);
  color: var(--main-color-black);
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 0.4rem;
  resize: none;
  height: 200px;
}
.contact-section .contact-container .form-container .contact-form textarea::placeholder {
  font-size: 0.9rem;
}
.contact-section .contact-container .form-container .contact-form .send-button {
  border: none;
  outline: none;
  background-color: var(--main-color-darkgrey);
  color: var(--main-color-white);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.contact-section .contact-container .form-container .contact-form .send-button:hover {
  background-color: var(--main-color-lightgrey);
  color: var(--main-color-darkgrey);
  transition: 0.3s linear;
}
.contact-section .contact-container .map iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
@media screen and (max-width: 1000px) {
  .contact-section .contact-container {
    margin: 0 auto;
    width: 90%;
  }
}
@media screen and (max-width: 700px) {
  .contact-section .contact-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-top: 2rem !important;
  }
  .contact-section .contact-container .map iframe {
    height: 400px;
  }
}

.cont-full {
  width: 100%;
  height: 100%;
}

#container-members {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../images/vlad-hilitanu-UIigFW4P7L8-unsplash.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 2rem 16vw;
}
#container-members h1 {
  display: block;
  text-align: center;
  font-family: var(--bold-font), Helvetica, sans-serif;
  font-size: 2.5rem;
  color: var(--main-color-orange);
  background-color: rgba(255, 255, 255, 0.5);
  border: 2px solid var(--main-color-orange);
  max-width: 450px;
  border-radius: 30px;
  padding: 0.2rem 1rem;
  margin-top: 12rem;
  margin-bottom: 5rem;
}
#container-members .mem-back {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  border: 2px solid var(--main-color-orange);
  width: 90%;
  align-items: center;
  justify-content: center;
  justify-items: start; /* ajouter cette propriété */
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  margin-bottom: 5rem;
  padding: 3rem;
}
@media (max-width: 1000px) {
  #container-members .mem-back {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
#container-members .mem-back .mem-list {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
  max-width: 300px;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}
#container-members .mem-back .mem-list img {
  grid-row: 1/3; /* occupe les deux premières lignes */
  width: 80px;
  margin-right: 10px;
  align-content: center;
}
#container-members .mem-back .mem-list .status {
  grid-row: 1/2; /* occupe la première ligne */
  text-align: center;
  color: var(--main-color-white);
  font-family: var(--bold-font);
}
#container-members .mem-back .mem-list .identity {
  grid-row: 2/3; /* occupe la deuxième ligne */
  text-align: center;
}
#container-members .mem-back .mem-list .identity span {
  text-transform: uppercase;
}

#container-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-image: url("../../images/james-wiseman-IebZAH6kaNw-unsplash.jpg");
  background-position: bottom;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.main-form {
  border: 1px solid var(--main-color-orange);
  min-height: 800px;
  position: relative;
  flex-grow: 1;
  /* padding-bottom:100px; */
}

.login-section {
  position: relative;
  border: 2px solid var(--main-color-orange);
  height: 490px;
  width: 400px;
  align-items: center;
  justify-content: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 40%);
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(30px);
  border-radius: 10px;
  transition: 0.3s;
  overflow: hidden;
}

.login-section:hover {
  box-shadow: 0 0 60px var(--main-color-orange);
}

.login-section .formbox {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.formbox h2 {
  text-align: center;
  font-size: 2em;
}

.formbox .input-box {
  position: relative;
  width: 340px;
  height: 50px;
  border-bottom: 2px solid var(--main-color-white);
  margin: 30px 0;
}

.input-box input {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1.2em;
  padding-right: 28px;
  color: var(--main-color-white);
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  font-size: 1.2em;
  color: var(--main-color-white);
  transition: 0.3s ease;
}

.input-box .icon {
  position: absolute;
  top: 13px;
  right: 0;
  font-size: 1.5em;
}

.input-box input:focus ~ label,
.input-box input:valid ~ label {
  top: -5px;
  color: var(--main-color-orange);
  font-size: 1em;
}

.rem-password {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
  margin: -15px 0 15px;
}

.rem-password label input {
  accent-color: var(--main-color-orange);
  margin-right: 5px;
}

.rem-password a {
  color: var(--main-color-white);
}

.rem-password a:hover {
  color: var(--main-color-orange);
}

.btn-val {
  width: 100%;
  height: 45px;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.3em;
  color: var(--main-color-white);
  background: var(--main-color-orange);
  box-shadow: rgba(0, 0, 0, 0.4);
}

.crea-account {
  font-size: 0.8em;
  text-align: center;
  margin: 25px;
}

.crea-account p a {
  color: var(--main-color-white);
  font-family: var(--bold-font), Helvetica, sans-serif;
  text-decoration: none;
}

.crea-account p a:hover {
  color: var(--main-color-orange);
}

.login-section .formbox.register {
  transform: translateY(-460px);
  transition: transform 0.7s ease;
}

.login-section.active .formbox.register {
  transform: translateY(0);
  transition-delay: 0.5s;
}

.login-section .formbox.login {
  transform: translateY(0px);
  transition: transform 0.7s ease;
}

.login-section.active .formbox.login {
  transform: translateY(430px);
  transition-delay: 0s;
}