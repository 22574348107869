.contact-section {
  //justify-content: center;
  width: 100%;
  background-image: url(../../images/triston-dunn-rfj_wOYQkus-unsplash.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  padding: 15rem 0 15rem;

  .contact-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    max-width: 1100px;
    width: 100%;
    margin: auto;
    background-color: var(--main-color-orange);
    box-shadow: 0 0 1rem hsla(0 0 0 / 16%);
    border-radius: 0.5rem;
    overflow: hidden;

    .form-container {
      padding: 20px;

      h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }

      .contact-form {
        display: grid;
        row-gap: 1rem;

        input {
          width: 100%;
          border: none;
          outline: none;
          background-color: var(--main-color-white);
          color: var(--main-color-black);
          padding: 10px;
          font-family: var(--reg-font);
          font-size: 0.9rem;
          border-radius: 0.4rem;
        }

        textarea {
          font-family: var(--reg-font);
          width: 100%;
          border: none;
          outline: none;
          background-color: var(--main-color-white);
          color: var(--main-color-black);
          padding: 10px;
          font-size: 0.9rem;
          border-radius: 0.4rem;

          resize: none;
          height: 200px;
        }

        textarea::placeholder {
          font-size: 0.9rem;
        }

        .send-button {
          border: none;
          outline: none;
          background-color: var(--main-color-darkgrey);
          color: var(--main-color-white);
          font-size: 1rem;
          font-weight: 500;
          cursor: pointer;
        }

        .send-button:hover {
          background-color: var(--main-color-lightgrey);
          color: var(--main-color-darkgrey);
          transition: .3s linear;
        }
      }
    }

    .map {
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .contact-container {
      margin: 0 auto;
      width: 90%;
    }
  }
  @media screen and (max-width: 700px) {

    .contact-container {
      grid-template-columns: 1fr;
      gap: 1rem;
      margin-top: 2rem !important;

      .map {
        iframe {
          height: 400px;
        }
      }
    }
  }
}

.cont-full {
  width: 100%;
  height: 100%;
}



