@font-face {
  font-family: fontRegular;
  src: url(../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: fontBold;
  src: url(../fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: fontLight;
  src: url(../fonts/Poppins-Light.ttf);
}

@font-face {
  font-family: fontItalic;
  src: url(../fonts/Poppins-Italic.ttf);
}

@font-face {
  font-family: fontBoldItalic;
  src: url(../fonts/Poppins-BoldItalic.ttf);
}