#container-part{
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../images/kigali-5256022_1920.jpg");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  padding: 2rem 16vw;

  h1{
    display: block;
    text-align: center;
    font-family: var(--bold-font), Helvetica, sans-serif;
    font-size: 2.5rem;
    color: var(--main-color-orange);
    background-color: rgba(255, 255, 255, 0.5);
    border: 2px solid var(--main-color-orange);
    max-width: 450px;
    border-radius: 30px;
    padding: 0.2rem 1rem;
    margin-top: 12rem;
    margin-bottom: 5rem;
    }

  .part-back{
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    border: 2px solid var(--main-color-orange);
    width: 90%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, .3);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    margin-bottom: 5rem;

    .part-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 30px;

      img{
        width: 80px;
        margin-right: 10px;
      }

      a{
        //text-align: center;
        color: var(--main-color-white);
        font-size: 1em;
      }
    }
  }
}