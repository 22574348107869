nav{
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  height: 80px;
  //background-color: var(--main-color-black);
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.6);
  padding: 0 100px;
  box-sizing: border-box;
  //transition: .5s;

  .Logo_loba{
    max-width: 180px;
    margin-top: 110px;
  }

  .navlinks{
    display: flex;
    justify-content: space-between;
    align-items: center;

    ul{
      display: flex;
      align-items: center;

      li{
        display: flex;
        list-style-type: none;
        padding: 0 20px;
        align-items: center;

        a {
          overflow: hidden;
          position: relative;
          display: inline-block;
          color: var(--main-color-black);
        }

        a::before,
        a::after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
        }
        a::before {
          background-color: var(--main-color-orange);
          height: 2px;
          bottom: 0;
          transform-origin: 100% 50%;
          transform: scaleX(0);
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        }
        a::after {
          content: attr(data-replace);
          height: 100%;
          top: 0;
          transform-origin: 100% 50%;
          transform: translate3d(200%, 0, 0);
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
          color: var(--main-color-orange);
        }

        a:hover::before {
          transform-origin: 0 50%;
          transform: scaleX(1);
        }
        a:hover::after {
          transform: translate3d(0, 0, 0);
        }

        a span {
          display: inline-block;
          transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        }

        a:hover span {
          transform: translate3d(-200%, 0, 0);
        }

        .donate{
          color: var(--main-color-white);
          background-color: var(--main-color-orange);
          padding: 6px 10px;
          border-radius: 5px;
          transition: .3s ease-in-out;
        }

        .donate:hover{
          color: var(--main-color-orange);
          background-color: var(--main-color-white);
          box-shadow: 0 0 0 2px var(--main-color-orange) inset;
          transition: .3s ease-out;
        }
      }
    }

    form{
      position: relative;
      display: inline-block;
      border: 1.5px solid var(--main-color-orange);
      border-radius: 5px;

      //margin: 100px 0 0;
      &:after {
        content: '▼';
        position: absolute;
        width: 30px;
        color: var(--main-color-white);
        font-weight: bold;
        font-size: 16px;
        right: 1px;
        bottom: 8px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        pointer-events: none;
        z-index: 2;
      }
      &:before {
        content: '';
        right: 8px;
        top: 2px;
        width: 30px;
        height: 34px;
        background: var(--main-color-orange);

        position: absolute;
        pointer-events: none;
        display: block;
        z-index: 1;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
      }
      select {
        position: relative;
        width: 80px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: var(--main-color-white);
        color: var(--main-color-black);
        border: none;
        outline: none;
        font-size: 14px;
        padding: 10px 9px;
        margin: 0;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        cursor: pointer;
        height: 38px;
      }
    }
  }
}

/* Animation burger */

#navbar{
  .containner {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
  }
}

path {
  transition: transform 500ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dasharray 500ms cubic-bezier(0.4, 0, 0.2, 1),
  stroke-dashoffset 500ms cubic-bezier(0.4, 0, 0.2, 1);
}
path:nth-child(1) {
  transform-origin: 36% 40%;
}
path:nth-child(2) {
  stroke-dasharray: 29 299;
}
path:nth-child(3) {
  transform-origin: 35% 63%;
}
path:nth-child(4) {
  stroke-dasharray: 29 299;
}
path:nth-child(5) {
  transform-origin: 61% 52%;
}
path:nth-child(6) {
  transform-origin: 62% 52%;
}
.active path:nth-child(1) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}
.active path:nth-child(2) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(3) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(4) {
  stroke-dasharray: 225 299;
  stroke-dashoffset: -72px;
}
.active path:nth-child(5) {
  transform: translateX(9px) translateY(1px) rotate(-45deg);
}
.active path:nth-child(6) {
  transform: translateX(9px) translateY(1px) rotate(45deg);
}
/* Fin animation burger */

@media screen and (min-width: 1150px) and (max-width: 1400px) {
  nav{
    padding: 0 30px;

    .navlinks{
      ul li a{
        font-size: calc(10 * var(--res));
      }
    }
  }
}

@media screen and (min-width: 926px) and (max-width: 1149px) {
  nav{
    padding: 0 5px;

    .navlinks{
      ul li a{
        font-size: calc(8 * var(--res));
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 925px) {
  #navbar {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .Logo_loba{
      max-width: 140px;
      margin-top: 5px;
      //margin-left: 10px;
    }

    .containner {
      display: block;
      padding-right: 3rem;
    }

    .navlinks {
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(0, 0, 0, .8);
      backdrop-filter: blur(7px);
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -100%;
      transition: all 0.5s ease;

      ul {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          margin: 20px 0;

          a {
            margin-left: 0;
            color: var(--main-color-white);
            font-size: calc(35 * var(--res));
          }

          .donate:hover{
            color: var(--main-color-orange);
          }
        }
      }

      form{
        margin-top: 2rem;
      }
    }

    .navlinks.mobile-menu {
      margin-left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}