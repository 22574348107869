section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem 0 12rem;
}

.sectionProjOne {
  //background-image: url('../../images/outdoors-3378794_1920.jpg');
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  z-index: 1;
  width: 100%;
  //clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);

  .proj-intro {
    /* max-width: 1100px; */
    margin: 0 auto;
    padding: 0 10px;

    h1 {
      display: block;
      text-align: center;
      font-family: var(--bold-font), Helvetica, sans-serif;
      font-size: 2.5rem;
      color: var(--main-color-orange);
      background-color: rgba(255, 255, 255, 0.5);
      border: 2px solid var(--main-color-orange);
      max-width: 400px;
      border-radius: 30px;
      padding: 0.2rem 1rem;
      margin-top: 12rem;
      margin-bottom: 5rem;
    }

    //section h2 {
    //  display: block;
    //  text-align: center;
    //  font-family: var(fontBold), Helvetica, sans-serif;
    //  font-size: 2rem;
    //  color: var(--main-color-black);
    //  background-color: var(--main-color-white);
    //  max-width: 300px;
    //  border-radius: 30px;
    //  padding: 0.2rem 1rem;
    //  margin-top: 5rem;
    //  margin-bottom: 2rem;
    //}

    .sec-pictos {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;

      .pictos-links {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        //width: 100%;
        margin-top: 2rem;
      }

      a img {
        width: 200px;
        height: 200px;
        margin: 1rem;
        //box-shadow: 5px 5px 5px rgba(0, 0, 0, 1);
      }
    }
  }

  .sec-sante {
    background-color: var(--main-color-orange);
    padding: 10em 0 10em;
    //clip-path: polygon(0 12%, 100% 0, 100% 88%, 0 100%);
    //margin: -12% 0;

    h2 {
      text-transform: uppercase;
    }

    &:nth-child(4n+2) {
      background: var(--main-color-orange);
    }

    &:nth-child(2n+3) {
      //background-image: url(../../images/jeffrey-riley-dsN1oZVba48-unsplash.jpg);
      background-size: cover;
      background-attachment: fixed;
      background-position: center;

      a h2 {
        color: var(--main-color-black);
      }
    }

    &:nth-child(4n+4) {
      background: var(--main-color-green);

      a h2 {
        color: var(--main-color-green);
      }
    }

    h2 {
      color: var(--main-color-orange);
      margin-top: 2rem;
    }

    h3 {
      font-family: var(--bold-font), Helvetica, sans-serif;
      color: var(--main-color-white);
      font-size: 1.5rem;
      padding-top: 2rem;
    }

    .content {
      max-width: 1000px;
      line-height: 1.5rem;
      font-size: 1.2rem;
      padding-top: 1rem;

      li {
        padding-top: 1rem;
        //padding-bottom: 10rem;
      }
    }
  }

  @media screen  and (max-width: 925px) {
    h1{
      margin-bottom: 2rem;
    }
    .sec-pictos{
      padding: 0 0 5rem;

      .pictos-links{
        margin-top: 1rem;
      }
    }

    .sec-sante{
      padding: 5rem 2rem 10rem;
    }
  }
}