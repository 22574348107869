*,
::before,
::after{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

body{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--reg-font), Helvetica, sans-serif;
  color: var(--main-color-white);
  background-color: var(--main-color-black);
  overflow-x: hidden;

  section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10rem 0 10rem;

    h2{
      display: block;
      text-align: center;
      font-family: var(--bold-font), Helvetica, sans-serif;
      font-size: 2rem;
      color: var(--main-color-black);
      background-color: var(--main-color-white);
      max-width: 300px;
      border-radius: 30px;
      padding: 0.2rem 1rem;
      margin-bottom: 5rem;
    }

    p{
      max-width: 800px;
      line-height: 2rem;
      font-size: 1.2rem;
      padding-top: 1rem;
    }

    ul li{
        max-width: 800px;
        line-height: 2rem;
        //font-size: 1.2rem;
        padding-top: 1rem;
    }
  }

  section h2 {
    display: block;
    text-align: center;
    font-family: var(--bold-font), Helvetica, sans-serif;
    font-size: 1.5em;
    color: var(--main-color-black);
    background-color: var(--main-color-white);
    max-width: 300px;
    border-radius: 30px;
    padding: 0.2em 1em;
    /* margin-top: 5em; */
    margin-bottom: 2em;
  }

  .sectionOne {
    //background-image: url(../../images/africa-1089242_1920.jpg);
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    z-index: 1;
    width: 100%;

    .intro{
      max-width: 800px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 30px;
      color: var(--main-color-black);
      font-size: 1.7rem;
      margin-top: 5rem;
      padding: 5rem;
      line-height: 3rem;

      span{
        font-family: var(--bold-font);
        color: var(--main-color-orange);
      }
    }

    .sec-mission{
      background-color: var(--main-color-orange);
      padding: 10rem 0 10rem;

      h2 {
        text-transform: uppercase;
      }

      .mission-txt {
        //width: 65%;
        max-width: 1000px;
        line-height: 1.5rem;
        font-size: 1.2rem;
        padding-top: 1rem;
      }

      &:nth-child(4n+2){
        background: var(--main-color-orange);

        h2{
          color: var(--main-color-orange);
        }
      }

      &:nth-child(2n+3) {
        //background-image: url(../../images/african-5035645_1920.jpg);
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
      }

      &:nth-child(4n+4){
        background: var(--main-color-green);

        h2{
          color: var(--main-color-green);
        }
      }
    }

    @media screen  and (max-width: 925px) {
      .intro{
        margin-top: 2rem;
        font-size: 1.5rem;
        padding: 5rem 2rem 5rem;
      }

      .sec-mission{
        padding: 5rem 2rem 5rem;

        .mission-txt{
          width: 100%;
        }
      }
    }
  }
}