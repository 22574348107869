//variable pour le corps des typos
html {
  --res: calc(0.01 * 15vmin);
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.box-shadow {
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.8);
}

strong {
  font-family: var(--bold-font);
}

em {
  font-family: var(--italic-font);
}

strong em {
  font-family: var(--italic-bold-font);
}