.wb-page{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-image: url(../../images/domenico-loia-hGV2TfOh0ns-unsplash.jpg);
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;

  .wb-container{
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 20px;
    width: 100%;
    align-content: center;
    justify-content: center;
    padding: 15rem 0 15rem;

    a{
      display: flex;
      align-items: center;
      justify-content: center;

      .wb-card{
        width: 350px;
        height: 500px;
        perspective: 1000px;

        .wb-content-card{
          width: 100%;
          height: 100%;
          overflow: hidden;
          border-radius: 25px;
          transition: all 0.15s ease-out;

          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .wb-glow{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        opacity: 0.8;
        mix-blend-mode: hard-light;
        background: radial-gradient(circle at 50% 0%, rgb(184, 196, 211), transparent);
        transition: all 0.15s ease-out;
      }
    }
  }

  @media screen and (max-width: 1200px) {
    .wb-container{
      grid-template-columns: 1fr;
    }
  }
}


